<template>
  <div class="tc_data_recovery">
    <div class="form">
      <div class="form">
        <el-form ref="form" :model="form" label-width="130px" size="mini">
          <el-form-item label="备份路径：">
            <!-- <span>请先选择部门</span> -->
           <input type="file" :id="id" name="image" class="getImgUrl_file">
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="confirm-btn">
      <img src="../../../assets/images/confirm.png" alt />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "0",
        desc: "",
        value1: "",
        date: []
      }
    };
  },
  methods: {
    onSubmit() {
      //console.log("submit!");
    }
  }
};
</script>
<style lang="less">
.tc_data_recovery {
  .form {
    margin-top: 20px;
    width: 500px;
    .el-input__inner {
      width: 250px;
      padding-right: 20px;
    }
  }
  .confirm-btn {
    margin-top: 20px;
    margin-left: 270px;
  }
}
</style>